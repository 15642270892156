<template>
  <ion-page @click="dismissElement('ion-toast')">
    <ion-tabs>
      <ion-router-outlet id="main-content" @markMessagesRead="markMessagesRead"/>
      <ion-tab-bar slot="bottom" v-if="!loading" v-show="$route.path != '/messages'">
        <ion-tab-button @click="$router.push('/profile')" :selected="$route.path === '/profile'">
          <ion-icon :ios="personCircle" :md="personCircle"/>
          <ion-label>
            Profile
          </ion-label>
        </ion-tab-button>
        <ion-tab-button @click="$router.push('/messages')" :selected="$route.path === '/messages'">
          <ion-icon :ios="chatbubble" :md="chatbubble"/>
          <ion-label>
            Messages
          </ion-label>
          <ion-badge v-if="unreadMessages" color="danger">
            {{ unreadMessages }}
          </ion-badge>
        </ion-tab-button>
        <ion-tab-button v-if="rpmAvailable || rtmAvailable" @click="$router.push(rpmAvailable ? '/monitor' : '/pain')"
            :selected="['/monitor', '/vitals', '/pain'].some(path => $route.path.startsWith(path))">
          <ion-icon :ios="heart" :md="heart"/>
          <ion-label>
            {{ rpmAvailable ? 'Monitor' : 'Pain' }}
          </ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineComponent } from 'vue'
import { USER_CHECK } from '../store/actions/user'
import { PushNotifications } from '@capacitor/push-notifications'
import { LocalNotifications } from '@capacitor/local-notifications'
import { Capacitor } from '@capacitor/core'
import { dismissElement } from '../utils/overlay'
import axios from 'axios'

import {
  IonPage,
  IonLabel,
  IonTabButton,
  IonTabBar,
  IonTabs,
  IonRouterOutlet,
  IonIcon,
  IonBadge,
} from '@ionic/vue'
import {
  chatbubble,
  personCircle,
  heart,
} from 'ionicons/icons'

export default defineComponent({
  name: 'DefaultContainer',
  setup () {
    return {
      chatbubble,
      personCircle,
      heart,
      dismissElement,
    }
  },
  data () {
    return {
      loading: true,
      rpmAvailable: false,
      rtmAvailable: false,
      listeners: [],
      unreadMessages: 0,
    }
  },
  components: {
    IonPage,
    IonLabel,
    IonTabButton,
    IonTabBar,
    IonTabs,
    IonRouterOutlet,
    IonIcon,
    IonBadge,
  },
  methods: {
    setFirebaseID (firebaseID) {
      return axios.patch('/users/set_firebase_id/', {firebase_id: firebaseID})
    },
    onNotificationsError (errorMessage) {
      return this.setFirebaseID(null).then(() => {
        throw new Error(errorMessage)
      })
    },
    checkLocalNotificationsPermissions () {
      let errorMessage
      let resolvedPromise = Promise.resolve()
      if (!Capacitor.isPluginAvailable('LocalNotifications')) {
        return resolvedPromise
      }
      return LocalNotifications.areEnabled().then(({value}) => {
        errorMessage = `User ${this.userProfile.id} disabled LocalNotifications.`
        return value ? resolvedPromise : this.onNotificationsError(errorMessage)
      }).then(
        LocalNotifications.checkPermissions
      ).then(({display}) => {
        errorMessage = `User ${this.userProfile.id} denied LocalNotifications permissions.`
        return display === 'denied' ? this.onNotificationsError(errorMessage) : resolvedPromise
      })
    },
    initializePushNotifications () {
      if (!Capacitor.isPluginAvailable('PushNotifications')) {return}
      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      PushNotifications.requestPermissions().then(({receive}) => {
        if (receive === 'granted') {
          // Register with Apple/Google to receive push via APNS/FCM
          this.checkLocalNotificationsPermissions().then(PushNotifications.register)
        } else {
          this.onNotificationsError(`User ${this.userProfile.id} denied PushNotifications permissions.`)
        }
      })
      PushNotifications.addListener('registration', ({value}) => {
        this.setFirebaseID(value)
      }).then(listener => {
        this.listeners.push(listener)
      })
      PushNotifications.addListener('registrationError', (error) => {
        this.onNotificationsError(
          `PushNotifications registration error for user ${this.userProfile.id}: ${JSON.stringify(error)}`)
      }).then(listener => {
        this.listeners.push(listener)
      })
      PushNotifications.addListener('pushNotificationReceived', ({data}) => {
        if (data.topic === 'patient_message') {
          this.unreadMessages += 1
        }
      }).then(listener => {
        this.listeners.push(listener)
      })
      PushNotifications.addListener('pushNotificationActionPerformed', ({notification}) => {
        let topic = (notification.data || {}).topic
        if (topic === 'patient_message' && this.$route.path !== '/messages') {
          this.$router.push('/messages')
        } else if (topic === 'rtm_reminder' && this.$route.path !== '/pain') {
          this.$router.push('/pain')
        }
      }).then(listener => {
        this.listeners.push(listener)
      })
      PushNotifications.getDeliveredNotifications().then(({notifications}) => {
        /*
          When app is in the background, data payload is not parsed until the notification is tapped. Therefore, we
          cannot access the topic. So, assume only new message notifications have a title to compute unread.
          FMI: https://firebase.google.com/docs/cloud-messaging/concept-options#notification-messages-with-optional-data-payload
        */
        this.unreadMessages = notifications.filter(({title}) => title).length
        if (notifications.length) {
          PushNotifications.removeAllDeliveredNotifications()
        }
      })
    },
    markMessagesRead () {
      this.unreadMessages = 0
    },
  },
  created () {
    this.loading = true
    this.rpmAvailable = false
    this.rtmAvailable = false
    this.$store.dispatch(USER_CHECK).then(() => {
      let patient = this.userProfile.role_data
      this.rpmAvailable = patient && ['enrolled', 'pending'].includes(patient.rpm_status)
      this.rtmAvailable = patient && ['enrolled', 'pending'].includes(patient.rtm_status)
      this.loading = false
    })
    this.initializePushNotifications()
  },
  beforeUnmount () {
    this.listeners.forEach(listener => {
      listener.remove()
    })
    this.listeners = []
  },
  computed: mapGetters([
    'userProfile',
  ]),
})
</script>

<style scoped>
ion-tab-bar {
  height: 60px;
}
ion-tab-button {
  font-size: 20px;
}
</style>
